import 'owl.carousel';
import './insta';

jQuery( document ).ready(function($) {

    $('.owl-carousel').owlCarousel({
        margin: 20,
        loop: true,
        responsive:{
            0:{
                items:1,
                stagePadding: 50,
            }, 
            600:{
                items:1,
                stagePadding: 200,
            }, 
            900:{
                items: 3,
                stagePadding: 0
            }
        }
    });

    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href');

        e.preventDefault();

        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });

    $('#feed').juicer();

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();

        $.ajax({
            url: 'https://subs.sonymusicfans.com/submit',
            method: 'POST',
            data: $(this).serialize(),
            dataType: 'json',
            xhrFields: {
                withCredentials: false
            },
            success: function(data) {
                $('.newsletter').html('<div class="thanks">Thanks for signing up!</div>');
            },
            error: function() {
                alert('An error has occurred!');
            }
        });
    });
});