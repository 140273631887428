jQuery( document ).ready( function ( $ ) { 
    $.fn.juicer = function(options) {
        const $SELECTOR = $(this);
        let opts = $.extend({
            limit     : 5,
            feed_more : $('.juicer-button'),
            btn_msg   : 'View More'
        }, options);
        const init_limit = opts.limit;
        opts.feed_more.on('click', function (e) {
            if (opts.limit > 0) {
                opts.limit = 0;
                $(this).text('View Less');
            } else {
                opts.limit = init_limit;
                $(this).text(opts.btn_msg);
            }
            e.preventDefault();
        });
        $.ajax({
            url: 'https://www.juicer.io/api/feeds/masn',
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                console.log(data);
                let c = 1;
                let html = '';
                for (let item of data.posts.items) {
                    
                    let text = " ";
                    const img = item.image;
                    if (img) {
                        html += `
                                <div>
                                    <a href="${item.full_url}" target="_blank" style="background-image: url('${img}');" aria-label="${item.id}">
                                        <span>${text}</span>
                                    </a>
                                </div>
                            `;
                    } else {
                        html += `
                                <div>
                                    <a href="${item.full_url}" target="_blank" aria-label="${item.id}">
                                        <span style="opacity: 1;">${text}</span>
                                    </a>
                                </div>
                            `;
                    }
                    if (opts.limit > 0 && c++ >= opts.limit) {
                        html += `
                        <div>
                            <a href = "https://www.instagram.com/masonrupper" target = "_blank" class = "button"><img srcset="./dist/img/text/followmasnoninstagram.gif" class = "text" alt = "follow MASN on instagram"></a>
                        </div>
                        `;
                        $SELECTOR.html(html);

                        return false;
                    }
                }
                $SELECTOR.html(html);
                if (opts.limit === 0) {
                    $('#feed > div').css('display', 'block');
                }
            },
            error: function () {
                console.log('Error loading feed.');
            }
        });
    };
});